<template>
  <div class="form-group">
    <div class="row" v-if="label">
      <div class="col">
        <label
          v-bind:for="componentId"
          class="custom-label"
          v-bind:class="{
            'text-danger': error,
            'text-muted': labelDisabled,
          }"
          >{{ label }}</label
        >
        <i
          class="fas fa-question-circle ml-2"
          v-if="tooltip"
          v-tooltip="tooltipOptions"
        ></i>
      </div>
      <div class="col-auto" v-if="error">
        <div class="text-danger ml-auto">
          <small>
            <i v-if="required">{{ $t("requiredArea") }}</i>
          </small>
        </div>
      </div>
    </div>
    <div class="input-group" v-bind:class="{ 'input-group-sm': smallSize }">
      <!-- Prepend -->
      <template v-if="prepend">
        <div class="input-group-prepend">
          <span class="input-group-text" v-html="prepend"></span>
        </div>
      </template>
      <template v-if="this.$slots.prepend">
        <div class="input-group-prepend">
          <slot name="prepend"></slot>
        </div>
      </template>
      <!-- Prepend -->

      <input
        v-bind="$attrs"
        type="text"
        v-bind:name="componentId"
        v-bind:id="componentId"
        class="form-control text-right"
        v-bind:class="{
          'border-danger': error,
          'border-danger': isBiggerThanMax,
        }"
        v-bind:value="computedData.price"
        v-on:input="updateValue"
        v-bind:readonly="readOnly"
        v-bind:placeholder="placeholder"
        v-currency="currencySetting"
        v-on:focus="$event.target.select()"
        ref="currencyInput"
        v-on:blur="$emit('blur')"
      />

      <!-- Append -->
      <template v-if="append">
        <div class="input-group-append">
          <span class="input-group-text">{{ append }}</span>
        </div>
      </template>
      <template v-if="this.$slots.append">
        <div class="input-group-append">
          <slot name="append"></slot>
        </div>
      </template>
      <!-- Append -->
    </div>
  </div>
</template>
<script>
import { CurrencyDirective, setValue, parse } from "vue-currency-input";
export default {
  name: "WePriceInput",
  directives: {
    currency: CurrencyDirective,
  },
  props: {
    value: {
      default: () => {},
    },
    size: {
      default: "",
    },
    column: {
      default: () => {},
    },
    label: {
      default: null,
    },
    placeholder: {
      default: null,
    },
    error: {
      default: false,
    },
    required: {
      default: false,
    },
    prepend: {
      default: null,
    },
    append: {
      default: null,
    },
    readOnly: {
      default: false,
    },
    smallSize: {
      default: false,
    },
    tooltip: {
      default: "",
    },
    tooltipPosition: {
      default: "top",
    },
    labelDisabled: {
      default: false,
    },
    maxValue: {
      default: null,
    },
    minValue: {
      default: null,
    },
  },
  model: {
    prop: "value",
  },
  methods: {
    updateValue(e) {
      const value = e.target.value;
      this.computedData.price = value;
      this.computedData.unmask = parse(value, this.currencySetting);
    },
    inputFilter(value) {
      let result = value;

      if (value && this.maxValue != null && value > this.maxValue) {
        result = this.maxValue || 0;
      } else if (this.minValue != null) {
        result = this.minValue || 0;
      }

      return result;
    },
  },
  computed: {
    computedData() {
      return this.value;
    },
    currencySetting() {
      let data = {
        locale: "tr-TR",
        currency: null,
        precision: 2,
        allowNegative: false,
        autoDecimalMode: false,
      };
      return data;
    },
    componentId() {
      return (this.name || "input") + "-" + helper.getRandomInteger(1, 999999);
    },
    tooltipOptions() {
      const data = {
        content: this.tooltip,
        placement: this.tooltipPosition,
      };

      return data;
    },
    isBiggerThanMax() {
      return this.value.unmask
        ? this.value.unmask &&
            this.maxValue != null &&
            this.value.unmask > this.maxValue
        : false;
    },
  },
  watch: {
    "computedData.unmask": function (value) {
      if (value === null || value == "undefined") value = 0;
      setValue(this.$refs.currencyInput, this.inputFilter(value));
    },
  },
  mounted() {
    setValue(this.$refs.currencyInput, this.computedData.price);
  },
};
</script>